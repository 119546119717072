<template>
  <div>
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <h3 class="card-title ">{{ $t("operatorSearchPage.pageTitle") }} </h3>
          </CCardHeader>
          <CCardBody>
            <!-- <p>宿を検索して、次の操作を実施します。</p>
            <div class="bd-example mb-5">
              <li class="h6">宿の情報を編集すること</li>
              <li class="h6">宿のオペレーターの指定すること</li>
              <li class="h6">宿のステータス・ダッシュボードを確認すること</li>
            </div> -->
            <h4 class="sub-title">{{ $t("operatorSearchPage.userSearch") }}</h4>
            <p>{{ $t("operatorSearchPage.userSearchGuide") }}</p>
           <CForm>
            <CRow class="mb-2">
              <CCol lg="8">
                <CInput
                  :label="$t('operatorSearchPage.userEmail')"
                  class="search-input"
                  :placeholder="$t('operatorSearchPage.userEmailPlaceholder')"
                  horizontal
                  type="email"
                  v-model="query.email"
                  @keydown.enter="onEnterKeyPress($event,'email')"
                />
              </CCol>
              <CCol lg="2">
              <CButton
                  @click="onSearchClicked('email')"
                  class="info-button"
                  color="info"
              >
                <i class="icon cil-search"></i> {{ $t("operatorSearchPage.searchButton") }}
              </CButton>
                <!-- 検索 -->
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol lg="8">
                <CInput
                  :label="$t('operatorSearchPage.userName')"
                  class="search-input"
                  :placeholder="$t('operatorSearchPage.userNamePlaceholder')"
                  horizontal
                  type="text"
                  v-model="query.userName"
                  @keypress.enter="onEnterKeyPress($event,'userName')"
              />
              </CCol>
              <CCol lg="2">
                <CButton
                 @click="onSearchClicked('userName')"
                 class="info-button"
                 color="info"
                >
                <i class="icon cil-search"></i> {{$t("facilityRelationUserSearch.searchButton")}}
                </CButton>
                <!-- 検索 -->
              </CCol>
            </CRow>
            <transition name="fade">
              <CRow v-if="userNotFound">
                <CCol lg="6">
                  <CAlert color="danger">{{ $t("operatorSearchPage.userNotFoundAlert") }}</CAlert>
                </CCol>
              </CRow>
            </transition>
          </CForm>
              <div class="user-info-table">
                <dl class="row">
                  <dt class="col-sm-12">
                    <h4 class="sub-title">{{ $t("operatorSearchPage.userList") }}</h4>
                    {{ $t("operatorSearchPage.totalNumber") }}: {{userCount}} | {{ $t("operatorSearchPage.resultNumber") }}: {{searchUserCount}}
                  </dt>
                </dl>

                <CRow class="mb-2">
                  <CCol sm="3" class="paginationInput">
                    <CSelect
                      :label="$t('facilitySearchPage.pagenationLabel')"
                      placeholder=""
                      horizontal
                      type="number"
                      :options="userPagenationCounts"
                      :value.sync="userPagenationSelected"
                    />
                  </CCol>
                </CRow>
                <CDataTable
                  class="mb-0 table-outline"
                  hover
                  size="sm"
                  :items="users"
                  :fields="userTableFields"
                  head-color="light"
                  table-filter
                  column-filter
                  sorter
                  itemps-per-page-select
                  :items-per-page="userPagenationSelected"
                  pagination
                  v-if="!userLoading"
                  :tableFilter='{ placeholder: $t("tableFilter.placeholder"), label: $t("tableFilter.label") }'
                >
                  <!-- sorter
                :sorterValue.sync="tableSorter" -->
                  <td slot="userName" slot-scope="{ item }">
                    <strong>{{ item.userName }}</strong>
                  </td>
                  <td slot="fullName" slot-scope="{ item }">
                    <strong>{{ item.fullName }}</strong>
                  </td>
                  <td slot="mobile" slot-scope="{ item }">
                    <strong>{{ item.mobile }}</strong>
                  </td>
                  <td slot="email" slot-scope="{ item }">
                    <strong>{{ item.email }}</strong>
                  </td>
                  
                  <template #facility_user_relation_create="{item}">
                    <td class="py-2 text-center">
                      <CButton
                        color="info"
                        square
                        block
                        size="sm"
                        @click="onFacilityUserRelationSaveClicked(item)"
                        >{{ $t("operatorSearchPage.usersList.tableBody.facilityRelationButton") }}</CButton
                      >
                    </td>
                  </template>
                </CDataTable>
              </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


  </div>
</template>

<script>
import i18n from "../../../i18n"
import axios from "axios"
import config from "../../../config"

const ROLE = {
  ADMIN: "yado_operator_admin",
  STAFF: "yado_operator_staff",
  OPERATOR: "yado_operator",
  MEMBER: "liberty_member",
};

const rolePath = {
    [ROLE.ADMIN]: "admin",
    [ROLE.STAFF]: "staff"
  };

//会員システムのurl
const LIBERTY_MEMBER_SERVER_URL = config.LIBERTY_MEMBER_SERVER_URL;
// const LIBERTY_MEMBER_SERVER_URL = "https://points.liberty-resort.net/";

export default {
  name: "OperatorSearch",
  data() {
    return {
      userNotFound:false,
      userLoading:false,
      userCount: 0,
      searchUserCount: 0,
      userPagenationCounts: [{value:5},{value:10},{value:20},{value:50}],
      userPagenationSelected:5,

      query:{
        email:"",
        userName:""
      },
      
      // ユーザー検索結果
      users:[],

      // リクエストするユーザーデータ
      requestUser: {
        id:"",
        userName:"",
        firstName: "",
        lastName: "",
        familyName:"",
        givenName:"",
        email: "",
        telephone: "",
        description:"",
        status:null
      },

      userTableFields: [
        {
          key: "userName",
          label: `${i18n.t("operatorSearchPage.usersList.tableHeader.userName")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        {
          key: "fullName",
          label: `${i18n.t("operatorSearchPage.usersList.tableHeader.name")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        {
          key: "mobile",
          label: `${i18n.t("operatorSearchPage.usersList.tableHeader.telephone")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        {
          key: "email",
          label: `${i18n.t("operatorSearchPage.usersList.tableHeader.email")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        {
          key: "facility_user_relation_create",
          label: `${i18n.t("operatorSearchPage.usersList.tableHeader.facilityRelation")}`,
          sorter: false,
          filter: false,
          _classes: "text-center bg-light",
        }
      ],
    };
  },
  computed: {
    // ページ遷移先のロール判定
    pathRole() {
      const routeRole = this.$route.meta.roles[0];

      console.log(rolePath[routeRole])

      return rolePath[routeRole];
    }
  },
  methods: {

     onEnterKeyPress: function (event, value) {
      // 日本語入力中のEnterキー操作は無効
      if (event.keyCode !== 13) return;
      switch (value) {
        // FIXME:検索時にenterキー押すとページが更新されてしまう為修正が必要（原因不明）
        case "email":
          if (!this.query.email) return;
          break;
        // FIXME:ユーザー名取得apiが完成したら修正すること
        case "userName":
          if (!this.query.userName) return;
          break;
        default:
          return;
      }
      this.onSearchClicked(value);
    },


    // 検索ボタン押下
    onSearchClicked(value){
      var urlRequest;

      this.userNotFound = false;

      switch (value) {
        case "email":
          var email = encodeURIComponent(this.query.email);
          urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users?email=${email}`;
          this.getUsers(urlRequest);
          break;
          
        case "userName":
          urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users/${this.query.userName}`;
          this.getUsers(urlRequest);
          break;
        default:
          return;
      }
    },

    onUserRelationFacilitySearchPage(userId) {
      // this.$router.push({
      //   name: "FacilityRelation",
      //   params: { userId: userId },
      // });

      this.$router.push({
        path: `/${this.pathRole}/Operator/FacilityRelation/${userId}`
      });
    },

    // テーブルボディを作成
    createUserTableBodies(users){

      // 検索内容リセット
      // これを消すと連続で検索ボタン押下時にテーブルにデータが追加されてしまう
      this.users = [];

      users.forEach(user => {
        // 各ユーザーをオブジェクトとして作成
        // saveUser()する際にid,statusが必要の為、ここで用意
        // CoreUIのテーブルがフラットな構造のデータ出ないとソート機能が使えない為、オブジェクトを作っている
        var userObj = {
          id : user.id,
          userName:user.name,
          lastName:user.lastName,
          firstName:user.firstName,
          familyName:user.familyName,
          givenName:user.givenName,
          fullName:`${user.familyName} ${user.givenName}(${user.lastName} ${user.firstName})`,
          address1 : user.address.address1,
          mobile : user.mobile,
          email : user.email,
          status : user.status,
        }
        this.users.push(userObj);
      });

      console.log(this.users);
    },

    // ユーザー検索
    getUsers(urlRequest){


      this.userNotFound = false;

      //Login as registered user
      // var urlRequest = `${userRequestUrl}/rest/users?email=${email}`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {

          // レスポンスデータからテーブルボディ情報作成
          this.createUserTableBodies(resp.data)

          // 検索件数を格納
          this.searchUserCount = this.users.length;

          // 検索件数が0件の時
          if(this.searchUserCount == 0){
            this.userNotFound = true;
          }
          console.log(resp.data)

        })
        .catch((err) => {
          console.log(err);
          this.userNotFound = true;
        });

    },

    // 全ユーザー件数取得
    getUserCount(){

      //Login as registered user
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {

          // console.log(resp.data);
          this.userCount = resp.data;

        })
        .catch((err) => {
          console.log(err);
        });

    },

     // 宿xユーザーのリレーション登録ボタン押下
    onFacilityUserRelationSaveClicked(user){
      console.log("user情報");
      console.log(user);

      // ユーザー保存
      this.saveUser(user);
    },

    // 宿探しにユーザー保存
    saveUser(user){

      // 宿探しに保存するユーザー情報作成
      this.requestUser.id = user.id;
      this.requestUser.userName = user.userName;
      this.requestUser.lastName = user.lastName;
      this.requestUser.firstName = user.firstName;
      this.requestUser.familyName = user.familyName;
      this.requestUser.givenName = user.givenName;
      this.requestUser.email = user.email;
      this.requestUser.telephone = user.mobile;
      this.requestUser.description = "";
      this.requestUser.status = user.status;
      
      
      // console.log(this.requestUser)
      

      //Login as registered user
      // 宿探しに関連付けするユーザーを保存・すでに保存されてれば更新する
      var urlRequest = `/rest/user/${this.requestUser.id}`;
      // var urlRequest = `http://localhost:8080/rest/user/${this.requestUser.id}`;
      axios
        .put(
            urlRequest, 
            this.requestUser,
            {headers: { Authorization: `Bearer ${this.$keycloak.token}` },}
        )
        .then((resp) => {

          // レスポンス格納
          console.log(resp.data);

          this.onUserRelationFacilitySearchPage( this.requestUser.id);

        })
        .catch((err) => {
          console.log(err);
          this.relationSaveErrorModal = true;
        });
    }

  },
  beforeMount() {
      this.getUserCount();
  },
};
</script>
